/* Dashboard.css */

/* Add your custom styles for the Seven Plus button and image */

.title-container {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .title-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
  }
  
  .title-bubble {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    background-image: linear-gradient(135deg, #2193b0, #6dd5ed);
    color: white;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  

.six-plus-btn {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    background-color: #cfc625;
    color: white;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .six-plus-btn:hover {
    transform: scale(1.1);
    background-color: #b5ab22;
  }
  
  .six-plus-img {
    width: 100%;
    max-width: 200px;
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  
.seven-plus-btn {
    font-size: 1.5rem; /* Adjust font size */
    padding: 1rem 2rem; /* Adjust padding */
    border-radius: 1.5rem; /* Add more rounded corners */
    background-color: rgba(59, 130, 246, 0.8); /* Make the button more translucent */
    color: rgb(255, 255, 255); /* Set text color to black */
    transition: transform 0.3s; /* Add a transition for the transform property */
  }
  
  .seven-plus-btn:hover {
    transform: scale(1.1); /* Scale the button up slightly when hovered */
  }
  
  .seven-plus-img {
    width: 100%; /* Adjust the width */
    max-width: 200px; /* Adjust the maximum width */
    display: block;
    margin-bottom: 1rem;
    margin-left: auto; /* Center the image */
    margin-right: auto; /* Center the image */
  }
  
  .handwriting-btn {
    font-size: 1.5rem; /* Adjust font size */
    padding: 1rem 2rem; /* Adjust padding */
    border-radius: 1.5rem; /* Add more rounded corners */
    background-color: #d82e56; /* Set button background color */
    color: white; /* Set text color to white */
    transition: transform 0.3s, background-color 0.3s; /* Add a transition for the transform and background-color properties */
  }
  
  .handwriting-btn:hover {
    transform: scale(1.1); /* Scale the button up slightly when hovered */
    background-color: #b32445; /* Set a darker shade of the background color on hover */
  }
  
  .handwriting-img {
    width: 100%; /* Adjust the width */
    max-width: 200px; /* Adjust the maximum width */
    display: block;
    margin-bottom: 1rem;
    margin-left: auto; /* Center the image */
    margin-right: auto; /* Center the image */
  }
  
  .nine-plus-btn {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    background-color: #46c17c;
    color: white;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .nine-plus-btn:hover {
    transform: scale(1.1);
    background-color: #3a9b6c;
  }
  
  
  .nine-plus-img {
    width: 100%;
    max-width: 200px;
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  



  .ten-plus-btn {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    background-color: #7946c2;
    color: white;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .ten-plus-btn:hover {
    transform: scale(1.1);
    background-color: #5c339b;
  }
  
  .ten-plus-img {
    width: 100%;
    max-width: 200px;
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  .custom-grid {
    row-gap: 4rem; /* Adjust this value to your preference */
    column-gap: 14rem; /* Adjust this value to your preference for the gap between buttons on the same row */

  }


  .eleven-plus-btn {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    background-color: #ec4899;
    color: white;
    transition: transform 0.3s, background-color 0.3s;
  }
  
  .eleven-plus-btn:hover {
    transform: scale(1.1);
    background-color: #be185d;
  }
  
  .eleven-plus-img {
    width: 100%;
    max-width: 200px;
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  